<template>
  <div>
    <b-card>
      <div
        v-if="x.showon.includes(form_mode)"
        class="row"
        v-for="(x, index) in attribute_list"
        :key="x.eg"
      >
        <div class="col-lg-2">
          <b-form-checkbox v-model="checkbox[x.slug]" :value="true">
            {{ x.web_th }} [{{ x.slug }}]
          </b-form-checkbox>
        </div>
        <div class="col-lg-9">
          <b-form-group>
            <b-form-tags
              v-model="form[x.slug]"
              no-outer-focus
              :disabled="!checkbox[x.slug]"
              :placeholder="x.eg"
              separator=",;"
              remove-on-delete
              @input="update()"
            >
            </b-form-tags>
            <div v-if="pre_suggestion[x.slug]">
              {{ pre_suggestion[x.slug] }}
            </div>
          </b-form-group>
        </div>
        <div class="col-1">
          <b-badge @click="showTag(x.slug, x.web_th)" class="mr-1 ml-auto">
            i
          </b-badge>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BBadge,
  BButton,
  BCard,
  BFormGroup,
  BFormTags,
  BFormCheckbox,
  BFormRadio,
} from "bootstrap-vue";
import attribute from "@/assets/attribute.json";
export default {
  components: {
    BBadge,
    BCard,
    BFormGroup,
    BFormTags,
    attribute,
    BFormCheckbox,
    BButton,
    BFormRadio,
  },
  props: ["info", "form_mode"],
  data() {
    return {
      attribute_list: attribute,
      form: {},
      checkbox: {},
      pre_suggestion: {},
      selected: false,
    };
  },
  watch: {
    info() {
      this.init_form();
    },
  },
  created() {
    this.init_form();
  },
  computed: {
    suggestion() {
      return this.pre_suggestion;
    },
  },
  methods: {
    init_form() {
      const pre = {};
      const pre_check = {};
      const result = this.info.reduce((r, a) => {
        r[a.key] = r[a.key] || [];
        r[a.key].push(a.value);
        return r;
      }, Object.create(null));

      attribute.forEach((x) => {
        if (result[x.slug]) {
          pre[x.slug] = result[x.slug];
          pre_check[x.slug] = true;
        }
      });

      this.form = pre;
      this.checkbox = pre_check;
    },
    showTag(slug, web_th) {
      this.$http({
        method: "POST",
        url: `/product/attribute-tag`,
        data: {
          slug: slug,
        },
      })
        .then((x) => {
          this.pre_suggestion = Object.assign({}, this.pre_suggestion, {
            [slug]: x.data.data,
          });
        })
        .catch((e) => {});
    },
    async update() {
      const checkbox_true = _.omitBy(this.checkbox, (value, key) => {
        return !value;
      });
      const arr_key = _.keys(checkbox_true);

      const put_form = _.pickBy(this.form, function (v, k) {
        return _.includes(arr_key, k);
      });

      const arr = [];
      await Object.entries(put_form).forEach((x) => {
        const key = x[0];
        const pre_values = x[1];
        const value = [];
        pre_values.forEach((y) => {
          arr.push({
            key: key,
            value: y,
          });
        });
      });
      console.log("update", arr);
      this.$emit("update-info", arr);
    },
  },
};
</script>
