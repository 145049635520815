<template>
  <div>
    <!-- { "rental_price": true, "deposit_1": true, "type": true, "tags": true, "sorting_order": true, "lease_price": true, "rental_price_discount": true, "deposit_3": true, "attribute": true, "is_third_party": true, "deposit_2": true } -->
    <div class="table-responsive">
      Count : {{ products.length }}
      <table class="table">
        <thead>
          <tr>
            <td>ชื่อหน้าเว็บ</td>
            <td v-if="form.rental_price">ค่าเช่า</td>
            <td v-if="form.rental_price_discount">ส่วนลด</td>
            <td v-if="form.deposit_1">deposit_1</td>
            <td v-if="form.deposit_2">deposit_2</td>
            <td v-if="form.deposit_3">deposit_3</td>
            <td v-if="form.lease_price">ราคาในสัญญาเช่า</td>
            <td v-if="form.is_third_party">เลนส์นอกค่าย</td>
            <td v-if="form.short_description">short_description</td>
            <td v-if="form.long_description">long_description</td>
            <td v-if="form.feature">feature</td>
            <td v-if="form.web_items_include">web_items_include</td>
            <td v-if="form.attribute">attribute</td>
            <td v-if="form.sorting_order">sorting_order</td>
            <td>Save</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(x, index) in products" :key="index">
            <td>
              {{ x.web_name }} <br />
              <br />
              <b-button
                variant="relief-warning"
                class="btn-icon mb-1 mr-1"
                @click="open_new(x._id)"
                size="sm"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
              <b-button
                variant="relief-success"
                class="btn-icon mb-1 mr-1"
                @click="open_new_web(m[x._id].slug)"
                size="sm"
              >
                <feather-icon icon="MonitorIcon" />
              </b-button>
            </td>
            <td v-if="form.rental_price">
              <b-form-input v-model="m_rental_price[x._id]"> </b-form-input>
            </td>
            <td v-if="form.rental_price_discount">
              <b-form-input v-model="m_rental_price_discount[x._id]">
              </b-form-input>
            </td>
            <td v-if="form.deposit_1">
              <b-form-input v-model="m_deposit_1[x._id]"> </b-form-input>
            </td>
            <td v-if="form.deposit_2">
              <b-form-input v-model="m_deposit_2[x._id]"> </b-form-input>
            </td>
            <td v-if="form.deposit_3">
              <b-form-input v-model="m_deposit_3[x._id]"> </b-form-input>
            </td>
            <td v-if="form.lease_price">
              <b-form-input v-model="m_lease_price[x._id]"> </b-form-input>
            </td>
            <td v-if="form.is_third_party">
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                switch
                v-model="m_is_third_party[x._id]"
              />
            </td>

            <td v-if="form.short_description">
              <b-form-textarea
                id="textarea-default"
                placeholder="Textarea"
                rows="3"
                class="my-1"
                v-model="m_short_description[x._id]"
              />
            </td>
            <td v-if="form.long_description">
              <quill-editor
                v-model="m_long_description[x._id]"
                :options="snowOption"
                class="my-1"
              />
            </td>
            <td v-if="form.feature">
              <b-form-tags v-model="m_feature[x._id]" no-outer-focus>
                <template
                  #default="{
                    tags,
                    inputAttrs,
                    inputHandlers,
                    addTag,
                    removeTag,
                  }"
                >
                  <b-input-group aria-controls="my-custom-tags-list">
                    <input
                      v-bind="inputAttrs"
                      placeholder="New tag - Press enter to add"
                      class="form-control"
                      v-on="inputHandlers"
                    />
                    <b-input-group-append>
                      <b-button variant="primary" @click="addTag()">
                        Add
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <ul
                    id="my-custom-tags-list"
                    class="list-unstyled d-inline-flex flex-wrap mb-0"
                    aria-live="polite"
                    aria-atomic="false"
                    aria-relevant="additions removals"
                  >
                    <b-card
                      v-for="tag in tags"
                      :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                      :key="tag"
                      tag="li"
                      class="shadow-none border mt-1 mr-1 mb-0"
                      body-class="py-1 pr-2 text-nowrap"
                    >
                      <strong>{{ tag }}</strong>
                      <b-button
                        variant="link"
                        size="sm"
                        :aria-controls="`my-custom-tags-tag_${tag.replace(
                          /\s/g,
                          '_'
                        )}_`"
                        class="py-0"
                        @click="removeTag(tag)"
                      >
                        remove
                      </b-button>
                    </b-card>
                  </ul>
                </template>
              </b-form-tags>
            </td>
            <td v-if="form.web_items_include">
              <b-form-tags v-model="m_web_items_include[x._id]" no-outer-focus>
                <template
                  #default="{
                    tags,
                    inputAttrs,
                    inputHandlers,
                    addTag,
                    removeTag,
                  }"
                >
                  <b-input-group aria-controls="my-custom-tags-list">
                    <input
                      v-bind="inputAttrs"
                      placeholder="New tag - Press enter to add"
                      class="form-control"
                      v-on="inputHandlers"
                    />
                    <b-input-group-append>
                      <b-button variant="primary" @click="addTag()">
                        Add
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <ul
                    id="my-custom-tags-list"
                    class="list-unstyled d-inline-flex flex-wrap mb-0"
                    aria-live="polite"
                    aria-atomic="false"
                    aria-relevant="additions removals"
                  >
                    <b-card
                      v-for="tag in tags"
                      :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                      :key="tag"
                      tag="li"
                      class="shadow-none border mt-1 mr-1 mb-0"
                      body-class="py-1 pr-2 text-nowrap"
                    >
                      <strong>{{ tag }}</strong>
                      <b-button
                        variant="link"
                        size="sm"
                        :aria-controls="`my-custom-tags-tag_${tag.replace(
                          /\s/g,
                          '_'
                        )}_`"
                        class="py-0"
                        @click="removeTag(tag)"
                      >
                        remove
                      </b-button>
                    </b-card>
                  </ul>
                </template>
              </b-form-tags>
            </td>
            <td v-if="form.attribute" class="p-0">
              <attribute-info
                :form_mode="m_item_type[x._id]"
                :info="m_attribute[x._id]"
                @update-info="updateAttribute(x._id, $event)"
              >
              </attribute-info>
            </td>
            <td v-if="form.sorting_order">
              <b-form-input v-model="m_sorting_order[x._id]"> </b-form-input>
            </td>
            <td class="p-0">
              <b-button
                :disabled="loading"
                variant="relief-success"
                class="btn-icon"
                @click="saveProduct(x._id)"
                size="sm"
              >
                <feather-icon icon="SaveIcon" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BFormSelect,
  BFormTags,
  BFormTag,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormRadio,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormTextarea,
} from "bootstrap-vue";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "@/assets/scss/quill/quill.snow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { quillEditor } from "vue-quill-editor";

import AttributeInfo from "./BulkAttributeInfo.vue";

export default {
  components: {
    BCard,
    BFormInput,
    BInputGroup,
    BButton,
    BFormRadio,
    BFormCheckbox,
    BFormTextarea,
    BInputGroupAppend,
    quillEditor,
    BFormTags,
    AttributeInfo,
  },
  props: {
    info: {
      type: Array,
      default: () => {
        return [];
      },
    },
    form: {
      type: Object,
    },
  },
  watch: {
    // Watch for changes in the 'watchedProp'
    info(newValue, oldValue) {
      this.setInfoToField(newValue, oldValue);
    },
  },
  data() {
    return {
      products: [],
      m_item_type: [],
      m_rental_price: [],
      m_sorting_order: [],
      m_rental_price_discount: [],
      m_deposit_1: [],
      m_deposit_2: [],
      m_deposit_3: [],
      m_lease_price: [],
      m_is_third_party: [],
      m_short_description: [],
      m_long_description: [],
      m_feature: [],
      m_web_items_include: [],
      m_attribute: [],

      m: [],
      loading: false,
      snowOption: {
        theme: "snow",
      },
    };
  },

  methods: {
    updateAttribute(id, updatedInfo) {
      this.m_attribute[id] = updatedInfo;
    },
    setInfoToField(x, old) {
      this.products = x;
      this.products.forEach((product) => {
        this.$set(this.m, product._id, product || 0);
        this.$set(this.m_rental_price, product._id, product.rental_price || 0);
        this.$set(
          this.m_sorting_order,
          product._id,
          product.sorting_order || 0
        );
        this.$set(
          this.m_rental_price_discount,
          product._id,
          product.rental_price_discount || 0
        );

        this.$set(this.m_deposit_1, product._id, product.deposit_1 || 0);
        this.$set(this.m_deposit_2, product._id, product.deposit_2 || 0);
        this.$set(this.m_deposit_3, product._id, product.deposit_3 || 0);
        this.$set(this.m_lease_price, product._id, product.lease_price || 0);
        this.$set(
          this.m_is_third_party,
          product._id,
          product.is_third_party || false
        );
        this.$set(
          this.m_short_description,
          product._id,
          product.short_description
        );
        this.$set(
          this.m_long_description,
          product._id,
          product.long_description
        );
        this.$set(this.m_feature, product._id, product.feature);
        this.$set(
          this.m_web_items_include,
          product._id,
          product.web_items_include
        );

        this.$set(this.m_attribute, product._id, product.attribute);
        this.$set(this.m_item_type, product._id, product.item_type);

        // this.selectcategories[product._id] = product.categories || "";
        // this.m_brand[product._id] = product.brand || "";
        // this.m_item_type[product._id] = product.item_type || "";
      });
    },
    open_new(id) {
      window.open("/product/view/" + id, "_blank");
    },

    open_new_web(id) {
      if (process.env.NODE_ENV === "development") {
        window.open("http://localhost:3000/rent/" + id, "_blank");
      } else {
        window.open("https://web.lenslineup.com/rent/" + id, "_blank");
      }
    },
    saveProduct(id) {
      this.loading = true;
      this.$http({
        method: "PUT",
        url: `/product/` + id,
        data: {
          sorting_order: this.m_sorting_order[id],
          is_third_party: this.m_is_third_party[id],
          deposit_1: this.m_deposit_1[id],
          deposit_2: this.m_deposit_2[id],
          deposit_3: this.m_deposit_3[id],
          rental_price_discount: this.m_rental_price_discount[id],
          lease_price: this.m_lease_price[id],
          is_third_party: this.m_is_third_party[id],
          feature: this.m_feature[id],
          web_items_include: this.m_web_items_include[id],
          short_description: this.m_short_description[id],
          long_description: this.m_long_description[id],
          attribute: this.m_attribute[id],
        },
      })
        .then((x) => {
          this.loading = false;
          this.$root.$emit("product-bulk-filter-bar-fetch");
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "แก้ไขอุปกรณ์",
              icon: "CheckIcon",
              text: "แก้ไขข้อมูลอุปกรณ์แล้ว",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
[dir] .ql-toolbar.ql-snow {
  background-color: #cdcdcd;
}
</style>
