<template>
  <div>
    <b-form-select
      v-model="form.categories"
      :options="product_categories_options"
    />
    <div class="demo-inline-spacing">
      <b-form-checkbox v-model="form.checkbox.filter_no_data">
        Only no data
      </b-form-checkbox>
    </div>
    <hr />
    <div class="demo-inline-spacing">
      <b-form-checkbox v-model="form.checkbox.rental_price">
        ราคา
      </b-form-checkbox>
      <b-form-checkbox v-model="form.checkbox.rental_price_discount">
        ราคาส่วนลด
      </b-form-checkbox>
      <b-form-checkbox v-model="form.checkbox.deposit_1">
        เงินประกัน 1
      </b-form-checkbox>
      <b-form-checkbox v-model="form.checkbox.deposit_2">
        เงินประกัน 2
      </b-form-checkbox>
      <!-- <b-form-checkbox v-model="form.checkbox.deposit_3">
        เงินประกัน 3
      </b-form-checkbox> -->
      <b-form-checkbox v-model="form.checkbox.lease_price">
        ราคาในสัญญาเช่า
      </b-form-checkbox>
      <b-form-checkbox v-model="form.checkbox.attribute">
        Attribute
      </b-form-checkbox>
      <!-- <b-form-checkbox v-model="form.checkbox.type"> Type </b-form-checkbox> -->
      <b-form-checkbox v-model="form.checkbox.tags"> Tags </b-form-checkbox>
      <b-form-checkbox v-model="form.checkbox.sorting_order">
        Sorting Order
      </b-form-checkbox>
      <b-form-checkbox v-model="form.checkbox.is_third_party">
        is_third_party
      </b-form-checkbox>

      <b-form-checkbox v-model="form.checkbox.short_description">
        Short Description
      </b-form-checkbox>
      <b-form-checkbox v-model="form.checkbox.long_description">
        Long Description
      </b-form-checkbox>
      <b-form-checkbox v-model="form.checkbox.feature">
        Feature
      </b-form-checkbox>
      <b-form-checkbox v-model="form.checkbox.web_items_include">
        Web Item Include
      </b-form-checkbox>
    </div>

    <b-button
      variant="relief-success"
      class="btn-icon mt-2"
      @click="fetchProducts"
      >Search
    </b-button>
  </div>
</template>
<script>
import {
  BButton,
  BCard,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue";
import ProductCategories from "@/assets/product_categories.json";
import BulkEditTable from "./BulkEditTable.vue";

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BFormCheckbox,
    BulkEditTable,
  },
  data() {
    return {
      form: {
        categories: "Canon Fullframe DSLR Camera",
        checkbox: {
          sorting_order: true,
        },
      },
      table_data: [],
      product_categories_options: ProductCategories,
    };
  },
  created() {
    this.loadInitialSettings();
  },
  mounted() {
    this.$root.$on("product-bulk-filter-bar-fetch", this.fetchProducts);
  },
  beforeDestroy() {
    this.$root.$off("product-bulk-filter-bar-fetch", this.fetchProducts);
  },
  methods: {
    fetchProducts() {
      this.saveSettings();
      this.$http({
        method: "POST",
        url: `/product/search/category`,
        data: this.form,
      }).then(async (x) => {
        this.table_data = x.data.data;
        await this.$emit("filter_data", {
          info: this.table_data,
          form: this.form.checkbox,
        });
      });
    },

    async saveSettings() {
      this.$cookies.set("selectedColumns", JSON.stringify(this.form.checkbox));
      this.$cookies.set("selectedCategory", this.form.categories);
    },
    loadInitialSettings() {
      const temp_category = this.$cookies.get("selectedCategory");
      const temp_checkbox = this.$cookies.get("selectedColumns");

      if (temp_category) {
        this.form.categories = temp_category;
      }
      if (temp_checkbox) {
        try {
          this.form.checkbox = temp_checkbox;
        } catch (e) {
          console.error("Error parsing columns data from cookies:", e);
        }
      }
    },
  },
};
</script>
