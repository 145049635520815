<template>
  <div>
    <b-tabs v-model="activeTabIndex">
      <b-tab title="อุปกรณ์">
        <b-card>
          <div class="d-flex justify-content-end pb-1">
            <b-button
              size="sm"
              variant="relief-success"
              v-b-modal.modal-add-product
            >
              <feather-icon icon="PlusIcon" /> เพิ่มอุปกรณ์
            </b-button>
          </div>
          <list-table />
        </b-card>
      </b-tab>
      <b-tab title="แก้ไขอุปกรณ์ bulk">
        <b-card>
          <product-bulk-edit />
        </b-card>
      </b-tab>
    </b-tabs>
    <product-add />
  </div>
</template>

<script>
import { BCard, BCardText, BButton, BTabs, BTab } from "bootstrap-vue";
import ListTable from "./component/ListTable.vue";
import ProductAdd from "./component/Product-Modal.vue";
import ProductBulkEdit from "./component/ProductBulkEdit.vue";

export default {
  components: {
    BCard,
    BCardText,
    ListTable,
    ProductAdd,
    BButton,
    ProductBulkEdit,
    BTabs,
    BTab,
  },
  data() {
    return {
      activeTabIndex: 0,
    };
  },
};
</script>

<style></style>
