<template>
  <div>
    <product-bulk-filter-bar @filter_data="load_table" class="mb-2" />
    <product-edit-table :info="info" :form="form" />
  </div>
</template>

<script>
import ProductBulkFilterBar from "./ProductBulkFilterBar.vue";
import ProductEditTable from "./BulkEditTable.vue";

export default {
  data() {
    return {
      info: [],
      form: {},
    };
  },
  components: {
    ProductBulkFilterBar,
    ProductEditTable,
  },
  methods: {
    load_table(x) {
      this.info = x.info;
      this.form = x.form;
    },
  },
};
</script>
